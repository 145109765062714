<template>
  <div class="Propage">
    <!-- 产品详情协议 -->
    <div v-if="protocolList">
      <div class="proMain" v-for="item in protocolList" :key="item.contractName">
        <a class="prolink" @click="goloanUrl(item.contractUrl, item.contractName)">
          <div class="proLine">
            <div class="proText">{{ item.contractName }}</div>
            <van-icon class="proIcon" name="arrow" />
          </div>
        </a>
      </div>
    </div>
    <div v-if="protocolLists">
      <div class="proMain" v-for="item in protocolLists" :key="item">
        <a class="prolink" @click="goloanUrls(item.contractUrl, item.contractName)">
          <div class="proLine">
            <div class="proText">{{ item.contractName }}</div>
            <van-icon class="proIcon" name="arrow" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<style scoped lang='less' src='./index.less'></style>
<script>
import { onMounted, toRefs, reactive } from 'vue'
import { Jump } from '@/utils/routh'
import API from '../../server/api'
import { useRoute } from 'vue-router'
export default {
  setup () {
    const route = useRoute()
    const state = reactive({
      newList: [],
      newObj: null,
      protocolList: [],
      protocolLists: [],
      loanNo: '',
      type:0,
      creditNo:''
    })
    // 获取用户协议
    const getProtocols = async () => {
      const protocolList = await API.getProtocol({type:1})
      state.protocolList = protocolList.result
    }
    const getProtocol = async () => {
      //贷款后协议 type 3  贷前type 2
      const protocolLists = await API.getProtocols({
        type: state.type,
        loanNo: state.loanNo,
        creditNo:state.creditNo
      })
      if(protocolLists.code==200){
      state.protocolLists =protocolLists.result
      }
      
      console.log(state.protocolLists, '协议列表')

    }
    const goloanUrl = (item, items) => {
      // window.open(item)
      Jump(item, items)
    }
    const goloanUrls = (item, items) => {
      // window.open(item)
      Jump(item, items)
    }
    onMounted(() => {
      
      // console.log(route, '9999999')
      console.log(route.fullPath, '路径')
      const url = route.fullPath
      const num = url.substr(-1, 1)
      console.log(num)
      // console.log(window.location.href,'哈哈哈');
      if (num == 3) {
        //修改标题
        document.title = "还款协议"
        state.type=3
        state.loanNo = localStorage.getItem('loanNos')
        getProtocol()
      }else if(num == 2){
        state.type=2
        state.creditNo=localStorage.getItem('creditNo')
        getProtocol()
      } else {
        document.title = "查看协议"
        getProtocols()
      }
    })
    return {
      ...toRefs(state),
      goloanUrl,
      getProtocol,
      goloanUrls
    }
  }
}
</script>
